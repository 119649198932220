import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default function Thanks() {
  return (
    <Wrapper>
      <Heading>Thanks for registering we will be in touch</Heading>
      <StyledLink to="/">Go back home</StyledLink>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #232323;
  justify-content: center;
`

const Heading = styled.h1`
  font-size: 112px;
  font-weight: bold;
  padding: 0 5%;
  color: white;
  @media (max-width: 1000px) {
    font-size: 82px;
  }
  @media (max-width: 600px) {
    font-size: 64px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  color: #ff9000;
  font-weight: bold;
  padding: 0 5%;
  font-size: 64px;
  text-align: left;
  cursor: pointer;
  @media (max-width: 1000px) {
    font-size: 48px;
  }
  @media (max-width: 600px) {
    font-size: 36px;
  }
`
